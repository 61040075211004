import '@cms/services/datadog'

import { useEffect } from 'react'
import type { AppContext, AppInitialProps, AppProps } from 'next/app'
import App from 'next/app'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import { useRouter } from 'next/router'
import type { Session } from 'next-auth'
import type { SSRConfig } from 'next-i18next'
import { appWithTranslation } from 'next-i18next'
import nextI18next from 'next-i18next.config'

import env from '@beam-australia/react-env'
import { getPageSlugFromRouterPath } from '@cms/analytics/utils'
import ErrorBoundary from '@cms/components/ErrorBoundary'
import { ADOBE_LAUNCH_SCRIPT_URL } from '@cms/utils/constants'
import type { CMSPageProps } from '@cms/utils/types'
import { getEmotionCache } from '@cms/utils/utils'
import HubSpotProvider from '@components/HubSpotForm/HubSpotProvider'
import KnaufFont from '@components/KnaufFont/KnaufFont'
import type { AnalyticsConfig } from '@knauf-group/cst-analytics'
import { AnalyticsProvider } from '@knauf-group/cst-analytics'
import knaufTheme, {
  globalThemeAlternativeFont,
} from '@knauf-group/ct-designs/themeConfigs/rebranding/theme'
import {
  getDirectionAttribute,
  isUnsupportedKnaufFont,
} from '@knauf-group/ct-designs/utils/utils'
import { useSetDir } from '@knauf-group/ct-shared-nextjs/client'
import SessionProvider from '@knauf-group/ct-shared-nextjs/nextauth/react'
import {
  type DeviceType,
  inferDeviceType,
} from '@knauf-group/ct-shared-nextjs/utils/deviceType'
import { createGlobalThemeBasedOnDevice } from '@knauf-group/ct-shared-nextjs/utils/theme'
import {
  usePageviewEvent,
  useSetNextLocaleCookie,
} from '@knauf-group/ct-shared-nextjs/web/hooks'
import { Logger } from '@lib/logger'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { AppCacheProvider } from '@mui/material-nextjs/v14-pagesRouter'
import type { IToggle } from '@unleash/nextjs'
import { FlagProvider, getFrontendFlags } from '@unleash/nextjs/client'

// TODO: remove ones all components switched from this font
import '@knauf-group/ct-designs/public/fonts/fonts.css'

const GlobalContextProvider = dynamic(() =>
  import('../cms/context/globalContext').then((mod) => mod.GlobalContextProvider),
)
const isStaticallyGeneratedPage = (pathname: string) =>
  ['/404', '/429', '/500'].includes(pathname)

type PageProps = Partial<CMSPageProps> & SSRConfig & { session: Session }

type AppOwnProps = {
  deviceType: DeviceType
  toggles: IToggle[]
}

type CustomAppProps = AppProps<PageProps> & AppOwnProps

const AppTranslated = appWithTranslation((props: AppProps) => {
  const router = useRouter()
  const { Component, pageProps } = props
  const analyticsPageName = getPageSlugFromRouterPath(router)

  usePageviewEvent(analyticsPageName)

  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta
          name="google-site-verification"
          content="dM5H6eBZYcaez4kX_7J6w4-VWigInUdY9KJRmC6R06k"
        />
      </Head>
      <ErrorBoundary>
        <Component {...pageProps} />
      </ErrorBoundary>
    </>
  )
}, nextI18next)

const HubSpotProviderWrapper = ({ children, hasContentFormFields }) =>
  hasContentFormFields ? <HubSpotProvider>{children}</HubSpotProvider> : children

const analyticsConfig: AnalyticsConfig = {
  appName: 'knauf_cms',
  adobeToken: ADOBE_LAUNCH_SCRIPT_URL,
}

const CMSApp = (props: CustomAppProps) => {
  const router = useRouter()
  const { deviceType, pageProps, toggles } = props
  const isAlternativeFont = isUnsupportedKnaufFont(router.locale)
  const direction = getDirectionAttribute(router.locale)
  const isRTL = direction === 'rtl'

  useSetNextLocaleCookie(router.locale)

  useEffect(() => {
    if (router.isReady) {
      document.documentElement.lang = router.locale
    }
  }, [router.asPath, router.isReady, router.locale])

  useSetDir()

  // if the page is a statically-generated page, don't use `UnleashProvider`
  // because we cannot pass environment variables to `UnleashProvider` dynamically
  // in statically-generated pages. For now the only statically-generated pages are
  // '/404', '/429 and '/500' pages
  if (isStaticallyGeneratedPage(router.pathname)) {
    return (
      <AppCacheProvider {...props} emotionCache={getEmotionCache(isRTL)}>
        <GlobalContextProvider>
          <CssBaseline />
          <SessionProvider session={pageProps.session}>
            <AnalyticsProvider config={analyticsConfig}>
              <AppTranslated {...props} />
            </AnalyticsProvider>
          </SessionProvider>
        </GlobalContextProvider>
      </AppCacheProvider>
    )
  }

  const theme = isAlternativeFont ? globalThemeAlternativeFont : knaufTheme

  // for pages that are not statically-generated, use `UnleashProvider` and make use of it
  // when initiating `ThemeProvider` by using `ThemeProviderWrapperUsingFlags` defined above.
  return (
    <AppCacheProvider {...props} emotionCache={getEmotionCache(isRTL)}>
      <GlobalContextProvider>
        <FlagProvider
          config={{
            url: env('UNLEASH_FRONTEND_API_URL'),
            clientKey: env('UNLEASH_FRONTEND_API_TOKEN'),
            appName: env('UNLEASH_APP_NAME'),
            bootstrap: toggles,
            disableRefresh: true,
            disableMetrics: true,
          }}
        >
          <ThemeProvider theme={createGlobalThemeBasedOnDevice(deviceType, theme, direction)}>
            <CssBaseline />
            {!isAlternativeFont && <KnaufFont />}
            <AnalyticsProvider config={analyticsConfig}>
              <HubSpotProviderWrapper hasContentFormFields={pageProps?.hasContentFormFields}>
                <SessionProvider session={pageProps.session} showLoadingSessionSpinner={false}>
                  <AppTranslated {...props} />
                </SessionProvider>
              </HubSpotProviderWrapper>
            </AnalyticsProvider>
          </ThemeProvider>
        </FlagProvider>
      </GlobalContextProvider>
    </AppCacheProvider>
  )
}

async function getFrontendFlagsWithFallback() {
  try {
    return await getFrontendFlags()
  } catch (error) {
    Logger.error(`[app] Error fetching feature flags: ${error.message}`, error)
    return { toggles: [] }
  }
}

CMSApp.getInitialProps = async (
  context: AppContext,
): Promise<Partial<CustomAppProps> & AppInitialProps> => {
  let deviceType: DeviceType = 'desktop' // fallback to desktop

  if (context.ctx.req) {
    const userAgent = context.ctx.req.headers['user-agent']
    deviceType = inferDeviceType(userAgent ?? '')
  }

  if (isStaticallyGeneratedPage(context.router.pathname)) {
    return { ...(await App.getInitialProps(context)) }
  }

  const [ctx, { toggles }] = await Promise.all([
    App.getInitialProps(context),
    getFrontendFlagsWithFallback(),
  ])
  return { ...ctx, deviceType, toggles }
}

export default CMSApp
